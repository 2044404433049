import { ConsoleService } from "@/services/ConsoleService"
import { ApiService } from "@/services/ApiService"
import { UserService } from "@/services/UserService"
import { TelegramService } from "@/services/TelegramService"
import { AnalyticsService } from "@/services/AnalyticsService"

/**
 * Init service
 */
export default async ({ config }) => {
	const service = {}
	const baseURL = config.baseURL || null
	const tgService = new TelegramService()
	const userService = new UserService({ baseURL })

	await tgService.init()
	await userService.init(tgService.getUser())

	service.config = config
	service.console = new ConsoleService()
	service.api = new ApiService({ baseURL, user: userService.getUser() })
	service.tg = tgService
	service.user = userService

	service.analytics = new AnalyticsService(userService.getUser(), config.analytics)
	service.analytics.init()

	return service
}

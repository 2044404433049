/**
 * Сервис аналитики
 */
export class AnalyticsService {
  /**
   * 
   * @param {*} user 
   * @param {*} config 
   */
  constructor(user, config) {
    this.user = user || {}
    this.config = config || {}
    this.isReady = false
  }

  get gtmId() {
    return this.config.gtmId || ""
  }

  get enabled() {
    return !!this.gtmId
  }

  /**
   * 
   * @returns 
   */
  async init() {
    if (!this.enabled || this.isReady) {
      return this
    }

    return await new Promise((resolve, reject) => {
      const firstScript = document.getElementsByTagName("script")[0]
      const gtmScript = document.createElement("script")
      const timeoutId = setTimeout(() => reject(), 3000)
      
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    
      gtmScript.setAttribute("async", true)
      gtmScript.setAttribute("src", `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`)
      gtmScript.addEventListener("load", () => {
        this.isReady = true
        
        clearTimeout(timeoutId)
        resolve(this)
      })

      firstScript.parentNode.insertBefore(gtmScript, firstScript)
    })
  }

  /**
   * 
   * @param {*} event 
   * @param {*} data 
   * @returns 
   */
  push(event, data) {
    event = String(event).toLowerCase()
    data = data || {}
    
    data.userId = this.user.id || 0
    data.srcMedium = this.user.source_medium || "display"
    data.srcCampaign = this.user.source_campaign || "general"

    console.info("AnalyticsService:push", { event, ...data })

    this.enabled && window.dataLayer.push({ ...data, event })
  }
}

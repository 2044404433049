import axios from "axios"

export class ApiService {
	constructor({ baseURL, user }) {
		this.baseURL = baseURL
		this.user = user
	}

	get userId() {
		return this.user.id
	}

	get client() {
		return axios.create({
			baseURL: this.baseURL,
			responseType: "json",
			headers: {
				"X-Tg-User-Id": this.userId
			}
		})
	}

	async pushEvent(event, data) {
		try {			
			await this.client.post("analyst/event/set", {
				category: "WEB_APP",
				event: String(event).toUpperCase(),
				data: data || {}
			})
		} catch ({ message }) {
			throw message
		}
	}

	/**
	 * updateFavorite
	 * @param {*} id
	 */
	async updateFavorite(id, type) {
		try {
			const { data } = await this.client.post("favorite/update", {
				user_id: this.userId,
				entity_id: id,
				entity_type: type
			})

			return data
		} catch {
			throw "Не удалось обновить избранное"
		}
	}

	/**
	 * importFavorite
	 * @param {*} ids
	 */
	async importFavorite(ids = [], type = "apartment") {
		try {
			const { data } = await this.client.get(`favorite/ids?user_id=${this.userId}&entity_type=${type}`)

			return data.items
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * fetchFavorites
	 */
	async fetchFavorites(type = "apartment") {
		try {
			const { data } = await this.client.get(`favorite/list?user_id=${this.userId}&entity_type=${type}`)

			return data
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * Получить список квартир по выбранному городу и ежемесячному платежу
	 * @param {*} param0
	 * @returns
	 */
	async fetchApartments({ cityIds, monthFee }) {
		const citiesForRequest = cityIds.reduce((accum, item) => (accum += `city_ids[]=${item}&`), "")
		const monthFeeRequest = `month_fee=${monthFee}`

		try {
			const { data } = await this.client.get("apartment/list?" + citiesForRequest + monthFeeRequest)

			return data
		} catch {
			throw "Не удалось собрать каталог"
		}
	}

	/**
	 * Получить список городов для выбора
	 */
	async fetchCities() {
		try {
			const { data } = await this.client.get("map/city/list")

			return data
		} catch {
			throw "Не удалось запросить список городов"
		}
	}

	/**
	 * Получить минимальную и максимальную цену по выбранным городам
	 */
	async fetchCostRange(cityIds) {
		const citiesForRequestArr = cityIds.reduce((accum, item) => (accum += `city_ids[]=${item}&`), "")
		const citiesForRequestStr = citiesForRequestArr.slice(0, citiesForRequestArr.length - 1)

		try {
			const { data } = await this.client.get("apartment/price-limits?" + citiesForRequestStr)

			return {
				min: parseInt(data.min),
				max: parseInt(data.max)
			}
		} catch {
			throw "Не удалось запросить диапазон цен"
		}
	}
}
